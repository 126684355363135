import React, {useEffect, useState} from 'react';
import './jester.css';

import cat from '../img/cat.svg';

import NavBar from "../components/NavBar";
import Socialsbar from "../components/Socialsbar";

function App() {

    function id(id) {
        return document.getElementById(id)
    }
    useEffect(() => {


    }, []);

    return (
        <div id='ben-page'>
            <NavBar></NavBar>
            <div id='ben-body'>
                <div id='ben-top-left-right'>
                    <div id='ben-intro-texts'>
                        <h1 id='ben-name'>Jester Shum</h1>
                        <h2>I'm a computer science student who combines programming with creativity and
                            practicality. </h2>
                        <div>
                            <h2>A little about me</h2>
                            <p>I am a owner of 5 cats</p>
                            <p>I cook a lot</p>
                            <p>I play basketball</p>

                        </div>

                    </div>
                    <div>
                        <img id='ben-avatar' src={cat}/>
                        <Socialsbar github={'https://github.com/BenjaminSoYH'} linkedin={'https://github.com/BenjaminSoYH'}></Socialsbar>


                    </div>

                </div>
                <div>
                    <h1 className='ben-section-title'>Things I've been part of</h1>
                    <div className='ben-teams-container'>
                        <h2>SEAL </h2>
                        <h3>description </h3>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default App;