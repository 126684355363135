import React from 'react';
import { useParams } from 'react-router-dom';
import MakeMemoryParagraph from "./MakeMemoryParagraph";
import './AngelHome.css'
import AngelHomeSelectParagraph from "./AngelHomeSelectParagraph";

function AngelHome() {
    const { name } = useParams();  // Get the "name" parameter from the URL

    return (
        <div className='angelhome-body'>

            <div className='mom-waterside-container'>
                <div>

                <h1>
                    😇 Angel 紀念集 😇
                </h1>
                <img className='mom-waterside' src='/memoryparagraph/mom-waterside.jpeg'/>
                </div>

            </div>

            <div className='angelhome-select-paragraph-container'>
                <AngelHomeSelectParagraph name={"Ray"} relation={"老公"}></AngelHomeSelectParagraph>


                <AngelHomeSelectParagraph name={"Eva"} relation={"妹"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"May"} relation={"妹"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"Yvonne"} relation={"妹"}></AngelHomeSelectParagraph>

                <AngelHomeSelectParagraph name={"Da"} relation={"老友"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"Daphne"} relation={"老友"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"Sam"} relation={"老友"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"Candy Hung"} relation={"教友"}></AngelHomeSelectParagraph>


                <AngelHomeSelectParagraph name={"Mimi and Jack"} relation={"教友"}></AngelHomeSelectParagraph>
                <AngelHomeSelectParagraph name={"Arthur"} relation={"教友"}></AngelHomeSelectParagraph>


            </div>

        </div>
    );
}

export default AngelHome;
