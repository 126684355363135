import logo from './logo.svg';
import './App.css';

import Benjamin from "./TabBenjamin/Benjamin";
import Angel from "./TabAngel/AngelHome";


import RainbowBoard from "./TabRainbow/RainbowBoard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Jester from "./TabJester/Jester";
import React from "react";
import Rants from "./TabRants/Rants";
import AngelHome from "./TabAngel/AngelHome";
import MakeMemoryParagraph from "./TabAngel/MakeMemoryParagraph";


function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Benjamin />} />
              <Route path="/Jester" element={<Jester />} />
              <Route path="/Benjamin" element={<Benjamin />} />
              <Route path="/Rants" element={<Rants />} />
              <Route path="/angelhome" element={<AngelHome />} />
              <Route path="angelhome/:name" element={<MakeMemoryParagraph />} />
          </Routes>
      </BrowserRouter>

  );
}

export default App;
